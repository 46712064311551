import React from 'react';
import {
  AptoCard,
  AptoCardContent,
  AptoRow,
  AptoCol,
  AptoHeader
} from '@apto/aptotude';
import { ProfileProps } from '../../types';
import { CloudFrontImage } from '../CloudFrontImage/CloudFrontImage';

export const CompanyProfile = (props: ProfileProps) => {
  const {
    company_name,
    company_phone,
    company_logo,
    company_address,
    company_city,
    company_state,
    company_zip
  } = props;

  if (company_name || company_logo || company_phone) {
    return (
      <AptoCard>
        <AptoCardContent className="CompanyProfile">
          <AptoRow>
            {company_logo && (
              <AptoCol xs="auto">
                <CloudFrontImage size={['115x0', '230x0']} img={company_logo} />
              </AptoCol>
            )}
            <AptoCol>
              {company_name && <AptoHeader type="3">{company_name}</AptoHeader>}
              <ul>
                {company_address && <li>{company_address}</li>}
                {company_city && company_state && company_zip && (
                  <li>
                    {company_city}, {company_state} {company_zip}
                  </li>
                )}
                {company_phone && <li>{company_phone}</li>}
              </ul>
            </AptoCol>
          </AptoRow>
        </AptoCardContent>
      </AptoCard>
    );
  }
  return null;
};
