import React from 'react';
import { AptoContainer, AptoRow, AptoCol, AptoHeader } from '@apto/aptotude';
import './landingPage.scss';

export const LandingPage = () => {
  return (
    <AptoContainer>
      <AptoRow className="vh100" justify="center">
        <AptoCol align="center" xs="3">
          <img src="/AptoLogo.png" alt="Apto Logo" />
          <br />
          <br />
          <AptoHeader center={true}>Apto Microsites</AptoHeader>
        </AptoCol>
      </AptoRow>
    </AptoContainer>
  );
};
