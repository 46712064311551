import React from 'react';
import './propertySite.scss';
import { AppContext, AppContextInterface } from '../../lib/AppContext';
import { AptoSpinner, AptoErrorPage } from '@apto/aptotude';
import { WrappedTemplate1 as Template1 } from '../Template1/Tempate1';
import axios from 'axios';
import { LandingPage } from '../LandingPage/LandingPage';
import { LeadProps, SiteProps, ProfileProps } from '../../types';
import { FormikActions } from 'formik';
import { Logger, LogMessage } from '../../lib/logger';

declare const window: any;

interface PropertySiteState extends AppContextInterface {
  loadingData: boolean;
  loadingErrors: boolean;
  notFoundError: boolean;
  site: SiteProps;
  slug: string | undefined;
  profile: ProfileProps;
  initialLeadFormValues: LeadProps;
  leadFormSubmitHandler: (
    values: LeadProps,
    actions: FormikActions<LeadProps>
  ) => void;
}

interface PropertySiteProps {
  slug?: string;
}

export class PropertySite extends React.Component<
  PropertySiteProps,
  PropertySiteState
> {
  public constructor(props: PropertySiteProps) {
    super(props);
    this.state = {
      loadingData: true,
      loadingErrors: false,
      notFoundError: false,
      profile: {},
      slug: this.props.slug,
      site: {},
      initialLeadFormValues: {
        firstName: '',
        lastName: '',
        email: '',
        message: '',
        phone: ''
      },
      leadFormSubmitHandler: this.leadSubmitHandler
    };
  }

  private _isMounted: boolean = false;

  get isMounted(): boolean {
    return this._isMounted;
  }

  set isMounted(mountedStatus: boolean) {
    this._isMounted = mountedStatus;
  }

  public componentDidMount() {
    this.isMounted = true;
    this.getSite();
  }

  public componentWillUnmount() {
    this.isMounted = false;
  }

  public render() {
    const { loadingData, loadingErrors, notFoundError } = this.state;
    const { slug } = this.props;

    if (loadingData) {
      return <AptoSpinner text="loading site" />;
    }

    if (!slug) {
      return <LandingPage />;
    }

    if (notFoundError) {
      return (
        <AptoErrorPage
          type="404"
          title="Property Site Not Found"
          message="The property site you were looking for is not available."
        />
      );
    }

    if (loadingErrors) {
      return <AptoErrorPage type="500" />;
    }

    return (
      <AppContext.Provider value={this.state}>
        <Template1 />
      </AppContext.Provider>
    );
  }

  public leadSubmitHandler = (
    values: LeadProps,
    actions: FormikActions<LeadProps>
  ) => {
    values.site_id = this.state.site.id;
    values.profile_id = this.state.site.profile_id;
    values.subject = `Message from microsite: ${this.state.site.site_name}`;

    axios({
      method: 'POST',
      url: `/api/sendLead`,
      data: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(resp => {
        if (this.isMounted) {
          if (Logger) {
            Logger.log(
              new LogMessage(
                `Apto Microsite | id:${this.state.site.id} | Sent Lead`
              )
            );
          }
          actions.resetForm();
          actions.setStatus({
            success: 'Message sent'
          });
          if (window.gtag) {
            window.gtag('event', 'sent_lead_form', {
              event_label: this.state.site.id,
              event_category: 'engagement',
              value: 1
            });
          }
        }
      })
      .catch(error => {
        if (this.isMounted) {
          actions.setSubmitting(false);
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            actions.setErrors(error.response.data.errors);
          } else {
            if (Logger) {
              Logger.log(
                new LogMessage(
                  `Apto Microsite | id:${this.state.site.id} | Sent Lead Error`
                )
              );
            }
            actions.setStatus({
              error: 'Could not send message'
            });
          }
        }
      });
  };

  private getSite() {
    const { slug } = this.props;

    this.setState({
      loadingErrors: false,
      notFoundError: false,
      loadingData: slug ? true : false
    });

    if (slug) {
      axios({
        method: 'GET',
        url: `/api/getSiteBySlug/${slug}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(resp => {
          if (this.isMounted) {
            if (Logger) {
              Logger.log(
                new LogMessage(
                  `Apto Microsite | id:${this.state.site.id} | Site Loaded`
                )
              );
            }
            this.setState(
              {
                profile: resp.data && resp.data.profile,
                site: resp.data && resp.data.site,
                loadingData: false
              },
              this.setDocumentTitle.bind(this, resp.data.site.site_name)
            );
          }
        })
        .catch(error => {
          if (this.isMounted) {
            const statusCode =
              (error.response &&
                error.response.status &&
                error.response.status) ||
              500;
            if (Logger) {
              Logger.log(
                new LogMessage(
                  `Apto Microsite | id:${
                    this.state.site.id
                  } | Site Load Error | statusCode ${statusCode}`
                )
              );
            }
            this.setState({
              loadingData: false,
              loadingErrors: true,
              notFoundError: statusCode === 404
            });
          }
        });
    }
  }

  private setDocumentTitle = (title: string) => {
    document.title = `${title} - Apto Listings`;
  };
}
