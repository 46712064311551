declare var LE: any; // this is in the widow object from the le.min.js file

export class LogMessage {
  public message: string;
  public serviceName?: string;

  constructor(message: string) {
    this.message = message;
    this.serviceName = 'microsite-client';
  }
}

interface LogEntriesLogger {
  log: (message: LogMessage) => void;
}

let LoggerInstance: null | LogEntriesLogger = null;

const initLogger = () => {
  if (LoggerInstance) {
    return LoggerInstance;
  }

  const token = process.env.REACT_APP_LOGENTRIES_TOKEN || null;

  if (!token) {
    console.log(
      'You have to add a LogEntries Token and make sure the script is in the dom'
    );
  } else {
    try {
      LE.init({
        trace: true,
        token
      });
      LoggerInstance = LE;
    } catch (ex) {
      // do nothing
    }
  }

  return LoggerInstance;
};

export const Logger = initLogger();
