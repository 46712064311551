import React from 'react';
import { AptoHeader, AptoParagraph } from '@apto/aptotude';
interface DescriptionProps {
  description?: string | null;
}

export const Description = (props: DescriptionProps) => {
  const { description } = props;

  if (!description || !description.length) {
    return null;
  }

  const p = description ? description.split('\r') : [];

  return (
    <div className="Description">
      <AptoHeader type="3" center={true}>
        DESCRIPTION
      </AptoHeader>
      {p.map((item, key) => {
        return <AptoParagraph key={key}>{item}</AptoParagraph>;
      })}
    </div>
  );
};
