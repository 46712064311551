import React from 'react';
import NumberFormat from 'react-number-format';
import { SiteProps } from '../../types';

export const isNotNumber = (price: string) => {
  const regex = /\,|\.|\$/gi;
  const pureNumberPrice = price.replace(regex, '');

  return isNaN(Number(pureNumberPrice));
};

export const HeroPrice = (props: SiteProps) => {
  const { price } = props;
  if (price && price.length) {
    return (
      <div className="HeroPrice">
        Offered at
        <br />
        {isNotNumber(price) ? (
          <span className="HeroPrice-price">{price}</span>
        ) : (
          <NumberFormat
            thousandSeparator={true}
            prefix={'$'}
            value={price}
            displayType={'text'}
            className="HeroPrice-price"
          />
        )}
      </div>
    );
  }
  return null;
};
