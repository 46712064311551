import React from 'react';
import { SiteProps } from '../../types';
import { AptoHeader } from '@apto/aptotude';

export const HeroInfo = (props: SiteProps) => {
  const { site_name, street_address, city, state, zip_code } = props;
  return (
    <div className="HeroInfo">
      {site_name && (
        <AptoHeader transform={false} type="1">
          {site_name}
        </AptoHeader>
      )}
      <address>
        {street_address ? (
          <React.Fragment>
            {street_address}
            <br />
          </React.Fragment>
        ) : null}
        {city ? city + ', ' : null} {state && state} {zip_code && zip_code}
      </address>
    </div>
  );
};
