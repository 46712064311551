import React from 'react';
import { AptoRow, AptoCol, AptoHeader, AptoParagraph } from '@apto/aptotude';

interface ListingDataProps {
  fields: any;
}

export const ListingData = (props: ListingDataProps) => {
  const { fields } = props;
  const fieldNames = fields ? Object.keys(fields) : [];

  if (!fields || !fieldNames.length) {
    return null;
  }

  const labelToJsx = (label: any) => {
    return (
      (fields[label] && (
        <AptoCol xs="6" sm="4" key={label}>
          <div className="ListingData-label">{label.replace(/_/g, ' ')}</div>
          {label === 'you_tube' ? (
            <a href={fields[label]} target="_blank" rel="noopener">
              {fields[label]}
            </a>
          ) : (
            <AptoParagraph>{fields[label]}</AptoParagraph>
          )}
        </AptoCol>
      )) ||
      null
    );
  };

  return (
    <div className="ListingData">
      <AptoHeader type="3" center={true}>
        DETAILS
      </AptoHeader>
      <AptoRow>{Object.keys(fields).map(labelToJsx)}</AptoRow>
    </div>
  );
};
