import React from 'react';
import {
  AptoCard,
  AptoCardContent,
  AptoRow,
  AptoCol,
  AptoHeader
} from '@apto/aptotude';
import { ProfileProps } from '../../types';
import { CloudFrontImage } from '../CloudFrontImage/CloudFrontImage';

export const BrokerProfile = (props: ProfileProps) => {
  const { name, phone, photo_url, email, broker_license, broker_title } = props;
  if (name || phone || photo_url || email) {
    return (
      <AptoCard>
        <AptoCardContent className="BrokerProfile">
          <AptoRow>
            {photo_url && (
              <AptoCol xs="auto">
                <CloudFrontImage size={['115x0', '230x0']} img={photo_url} />
              </AptoCol>
            )}
            <AptoCol>
              {name && <AptoHeader type="3">{name}</AptoHeader>}
              <ul>
                {broker_title && <li>{broker_title}</li>}
                {broker_license && <li>LIC: {broker_license}</li>}
                {phone && <li>{phone}</li>}
                {email && <li>{email}</li>}
              </ul>
            </AptoCol>
          </AptoRow>
        </AptoCardContent>
      </AptoCard>
    );
  }
  return null;
};
