import React from 'react';
import { AppContextInterface, AppContext } from './AppContext';

export interface AppContextProps {
  appContext: AppContextInterface;
}

export function withContext<P extends AppContextProps>(
  Component: React.ComponentType<P>
) {
  return function WrapperComponent(
    props: Pick<P, Exclude<keyof P, keyof AppContextProps>>
  ) {
    return (
      <AppContext.Consumer>
        {context => <Component {...props as P} appContext={context} />}
      </AppContext.Consumer>
    );
  };
}
