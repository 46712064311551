import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { PropertySite } from './components/PropertySite/PropertySite';

export const App = () => (
  <BrowserRouter>
    <Route
      exact={true}
      path="/:slug"
      render={props => <PropertySite slug={props.match.params.slug} />}
    />
    <Route exact={true} path="/" render={() => <PropertySite />} />
  </BrowserRouter>
);
