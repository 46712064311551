import React from 'react';
import { SiteProps, ProfileProps, LeadProps } from '../types';
import { FormikActions } from 'formik';

export interface AppContextInterface {
  site: SiteProps;
  profile: ProfileProps;
  slug: string | undefined;
  initialLeadFormValues: LeadProps;
  leadFormSubmitHandler: (
    values: LeadProps,
    actions: FormikActions<LeadProps>
  ) => void;
}

const AppContext = React.createContext<AppContextInterface>({
  site: {},
  profile: {},
  slug: undefined,
  initialLeadFormValues: {},
  leadFormSubmitHandler: (
    values: LeadProps,
    actions: FormikActions<LeadProps>
  ) => {}
});

export { AppContext };
