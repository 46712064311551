import React from 'react';
import { withContext, AppContextProps } from '../../lib/with-context';
import { ListingData } from '../ListingData/ListingData';
import { Description } from '../Description/Description';
import { AptoContainer, AptoRow, AptoCol } from '@apto/aptotude';
import { BrokerProfile } from '../BrokerProfile/BrokerProfile';
import './template1.scss';
import './template1-print.scss';
import { WrappedLeadForm as LeadForm } from '../LeadForm/LeadForm';
import { Hero } from '../Hero/Hero';
import { StaticMap } from '../StaticMap/StaticMap';
import { CompanyProfile } from '../ComanyProfile/CompanyProfile';

class Template1 extends React.Component<AppContextProps> {
  public render() {
    const { appContext } = this.props;
    return (
      <div className="Template1">
        <Hero site={appContext.site} />
        <AptoContainer fixed={true}>
          <AptoRow>
            <AptoCol xs="12" md="8" className="leftColumn">
              <Description
                description={appContext.site && appContext.site.description}
              />
              <ListingData
                fields={appContext.site && appContext.site.listing_data}
              />
            </AptoCol>
            <AptoCol xs="12" md="4" className="rightColumn">
              <BrokerProfile {...appContext.profile} />
              <LeadForm />
              <CompanyProfile {...appContext.profile} />
            </AptoCol>
          </AptoRow>
        </AptoContainer>
        <StaticMap site={appContext.site} />
      </div>
    );
  }
}

export const WrappedTemplate1 = withContext(Template1);
