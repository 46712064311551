import React from 'react';
import { HeroInfo } from '../HeroInfo/HeroInfo';
import { HeroPrice } from '../HeroPrice/HeroPrice';
import { SiteProps } from '../../types';
import { CloudFrontImage } from '../CloudFrontImage/CloudFrontImage';
import './hero.scss';
import { AptoContainer } from '@apto/aptotude';

interface HeroProp {
  site: SiteProps;
}

const { REACT_APP_CLOUD_FRONT_ROOT } = process.env;

export const Hero = (props: HeroProp) => {
  const { site } = props;
  let style = document.getElementById('hero-style');
  if (site && site.primary_photo_url && !style) {
    style = document.createElement('style');
    style.id = 'hero-style';
    style.innerHTML = `
      .Hero {
        background: url(${REACT_APP_CLOUD_FRONT_ROOT}/fit-in/1265x0/${
          site.primary_photo_url
        });
      }
      @media only screen and (min--moz-device-pixel-ratio: 1.5),
        (-o-min-device-pixel-ratio: 3/2),
        (-webkit-min-device-pixel-ratio: 1.5),
        (min-device-pixel-ratio: 1.5) {
          .Hero {
            background: url(${REACT_APP_CLOUD_FRONT_ROOT}/fit-in/2530x0/${
              site.primary_photo_url
            });
          }
      }
    `;
    document.getElementsByTagName('head')[0].appendChild(style);
  } else if (style) {
    style.remove();
  }

  return (
    <div className="Hero">
      <AptoContainer fixed={true}>
        {site && site.primary_photo_url && (
          <div className="Hero-PrintImage">
            <CloudFrontImage size={['350x200']} img={site.primary_photo_url} />
          </div>
        )}
        <HeroInfo {...site} />
        <HeroPrice {...site} />
      </AptoContainer>
    </div>
  );
};
