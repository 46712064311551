import React from 'react';
import { Formik, FormikActions } from 'formik';
import {
  AptoForm,
  AptoButton,
  AptoCard,
  AptoCardContent,
  AptoTextArea,
  AptoFormControlError,
  AptoAlert,
  AptoCardHeader,
  AptoFormInputHelper
} from '@apto/aptotude';
import { withContext, AppContextProps } from '../../lib/with-context';
import ReCAPTCHA from 'react-google-recaptcha';
import { LeadProps } from '../../types';
import axios from 'axios';

declare const window: any;

interface FormDataHolder {
  values: LeadProps;
  actions: FormikActions<LeadProps>;
}

class LeadForm extends React.Component<AppContextProps> {
  public recaptchaRef: React.RefObject<ReCAPTCHA>;
  public formRef: React.RefObject<HTMLDivElement>;
  public formDataHolder: FormDataHolder | null = null;

  public constructor(props: AppContextProps) {
    super(props);
    this.recaptchaRef = React.createRef();
    this.formRef = React.createRef();
  }

  public processReCaptcha = (
    values: LeadProps,
    actions: FormikActions<LeadProps>
  ) => {
    const { appContext } = this.props;

    this.formDataHolder = {
      values,
      actions
    };

    // hack for local tests and e2e tests to turn off recaptcha by adding
    // the class `bypass` to the div with className `LeadFormWrapper`
    let shouldValidate = true;
    if (this.formRef.current!.classList.contains('bypass')) {
      shouldValidate = false;
    }

    if (shouldValidate) {
      this.recaptchaRef.current!.execute();
    } else {
      appContext.leadFormSubmitHandler(values, actions);
    }
  };

  public submitForm = (token: string | null) => {
    const { appContext } = this.props;
    axios({
      method: 'POST',
      url: `/api/verifyRecaptcha`,
      data: JSON.stringify({ token }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(resp => {
        if (this.formDataHolder) {
          appContext.leadFormSubmitHandler(
            this.formDataHolder.values,
            this.formDataHolder.actions
          );
        }
      })
      .catch(() => {
        this.formDataHolder!.actions.setSubmitting(false);
        this.formDataHolder!.actions.setStatus({
          error: 'ReCaptcha is invalid'
        });
      })
      .finally(() => {
        this.recaptchaRef.current!.reset();
      });
  };

  public render() {
    const { appContext } = this.props;
    const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY || '';
    return (
      <div ref={this.formRef} className="LeadFormWrapper">
        <Formik
          initialValues={appContext.initialLeadFormValues}
          onSubmit={this.processReCaptcha}
          render={({
            values,
            errors,
            status,
            isSubmitting,
            handleChange,
            handleSubmit
          }) => (
            <AptoCard className="LeadForm">
              <AptoCardHeader>Get more information</AptoCardHeader>
              <AptoCardContent>
                {status && status.error && (
                  <AptoAlert variant="danger">{status.error}</AptoAlert>
                )}
                {status && status.success && (
                  <AptoAlert variant="success">{status.success}</AptoAlert>
                )}
                <AptoForm onSubmit={handleSubmit}>
                  <AptoFormInputHelper
                    label="First Name"
                    required={true}
                    name="firstName"
                    value={values.firstName}
                    error={errors.firstName}
                    change={handleChange}
                  />
                  <AptoFormInputHelper
                    label="Last Name"
                    required={true}
                    name="lastName"
                    value={values.lastName}
                    error={errors.lastName}
                    change={handleChange}
                  />
                  <AptoFormInputHelper
                    label="Phone number"
                    name="phone"
                    value={values.phone}
                    error={errors.phone}
                    change={handleChange}
                  />
                  <AptoFormInputHelper
                    label="Email"
                    name="email"
                    required={true}
                    value={values.email}
                    error={errors.email}
                    change={handleChange}
                  />
                  <AptoForm.Field required={true}>
                    <label htmlFor="input-message">Message</label>
                    <AptoTextArea
                      required={true}
                      name="message"
                      value={values.message}
                      error={errors.message}
                      onChange={handleChange}
                      id="input-message"
                    />
                    <AptoFormControlError error={errors.message} />
                  </AptoForm.Field>
                  <AptoButton
                    disabled={isSubmitting}
                    type="submit"
                    variant="primaryInverse"
                  >
                    Send
                  </AptoButton>
                  <ReCAPTCHA
                    badge="inline"
                    ref={this.recaptchaRef}
                    size="invisible"
                    sitekey={recaptchaKey}
                    onChange={this.submitForm}
                  />
                </AptoForm>
              </AptoCardContent>
            </AptoCard>
          )}
        />
      </div>
    );
  }
}

export const WrappedLeadForm = withContext(LeadForm);
